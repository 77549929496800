:root {
  --gap: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 12px 16px 16px 16px;
  gap: 12px;
  border-radius: 8px;
  font-size: 14px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap);
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: var(--gap);
}

.wasteType {
  font-size: 14px;
  font-weight: 600;

  &::first-letter {
    text-transform: capitalize;
  }
}

.headerRight {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
