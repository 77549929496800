.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
}

.title {
  font-weight: 400;
}

.selectFirstItem {
  width: 100%;
  padding: 28px 24px;
  border-radius: 8px;

  background: var(--gray-border);
  color: var(--secondary-text);
}
