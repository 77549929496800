.content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  border-radius: 12px;
}

.spacer {
  height: 14px;
}
