.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.unchecked {
  border: 1px solid var(--gray-400);
  background-color: transparent;
}

.checked {
  border: 5px solid var(--green);
}
