$mobile-width: 0;
$tablet-width: 480px;
$desktop-width: 1000px;
$largeDesktop-width: 1700px;
$extraLargeDesktop-width: 2000px;

@mixin mobile {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet-width + 1px}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop-width + 1px}) {
    @content;
  }
}
@mixin largeDesktop {
  @media (min-width: #{$largeDesktop-width + 1px}) {
    @content;
  }
}
@mixin extraLargeDesktop {
  @media (min-width: #{$extraLargeDesktop-width + 1px}) {
    @content;
  }
}
