.container {
  display: flex;
  padding: 16px;
  gap: 36px;
  border-radius: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--dark-green);
  box-shadow: 0px 3px 24px var(--green-box-shadow);
  color: var(--white);
  cursor: pointer;
  align-items: center;
}

.containerTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  gap: 6px;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.text {
  font-size: 14px;
}

.containerIcon {
  padding: 6px;
  border-radius: 8px;
  background-color: var(--green);
  height: 36px;
  width: 36px;
}
