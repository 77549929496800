.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border: 1px solid var(--gray-border);
  border-radius: 50%;

  cursor: pointer;
}

.secondary {
  background: var(--gray-100);
}
