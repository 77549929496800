@import "../../../assets/styles/_breakpoints.scss";

.container {
  display: flex;
  flex-direction: column;

  height: 100%;
  gap: 4px;

  padding: 16px 12px;
  @include desktop {
    padding: 16px 24px;
  }

  background: var(--white);
  border: 2px solid transparent;
  border-radius: 8px;

  cursor: pointer;
}

.text {
  font-size: 14px;
  &::first-letter {
    text-transform: capitalize;
  }
}

.id {
  font-size: 12px;
  color: var(--secondary-text);
}

.selected {
  border: 2px solid var(--green);
  background-color: var(--green-light);
}
