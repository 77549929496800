.container {
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--secondary-text);
  cursor: pointer;
  line-height: 14px;
  background: transparent;
}

.lang {
  padding-top: 2px;
}
