.customToast {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 16px;
  color: var(--primary-text);
  background-color: var(--white);
  font-family: var(--default-font-family);
}

.customToastBody {
  font-size: 14px;
}
