@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700;800&display=swap");
@import "./assets/styles/variables.scss";

* {
  box-sizing: border-box;
  text-rendering: geometricPrecision;
}

body {
  margin: 0;
  font-family: var(--default-font-family);
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  line-height: 1;
}

[data-input][type="number"]::-webkit-inner-spin-button,
[data-input][type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

a {
  text-decoration: none;
}
