.wrapper {
  display: grid;
  grid-template-columns: 44px 4fr 4fr 5fr 44px;
  gap: 4px 12px;
  align-items: center;

  font-size: 14px;
}

.wrapperItems {
  display: flex;
  gap: 16px;
  align-items: center;
}

.weight {
  font-size: 20px;
  font-weight: 600;
  border-radius: 4px;
  background: var(--gray-100);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  overflow: hidden;
  white-space: nowrap;
}

.unit {
  color: var(--primary-text);
}

.label {
  font-size: 12px;
  color: var(--secondary-text);
}

.primaryLabel {
  color: var(--green);
}

.textError {
  color: var(--red);
}

.marginLeft {
  margin-left: 4px;
}

.error {
  color: var(--red);
  border: 2px solid var(--red);
}
