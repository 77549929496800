.container {
  padding: 0 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.tab {
  position: relative;
  padding: 20px 0 14px 0;
  cursor: pointer;
}

.active {
  color: var(--green);
  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--green);
  }
}
