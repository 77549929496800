.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  font-size: 14px;
  font-weight: 400;
}

.containerTypes {
  display: grid;
  gap: 8px 16px;
  grid-template-columns: 1fr 1fr 1fr;
}
