.layout {
  display: flex;
  background-color: #f9f9f9;
}

.dark {
  background-color: #191919;
}
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
}
