.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  font-size: 14px;
  cursor: pointer;
}

.languagesListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.languageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }
}

.language {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}

.buttonsContainer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}
