.imageContainer {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 57px;
}

.errorSubheader {
  font: normal normal 600 16px/34px Sora;
  margin: 37px 0 5px 0;
}

.errorText {
  text-align: center;
  font: normal normal normal 12px/18px Sora;
  letter-spacing: 0px;
  color: #868b94;
  width: 260px;
  margin: 0;
}
