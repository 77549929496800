.container {
  height: 54px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 20px;
  color: var(--primary-text);
  font-weight: 600;
}

.subtitle {
  font-size: 14px;
  padding-top: 2px;
  color: var(--secondary-text);
}

.title,
.subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 415px);
}
