.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  height: 56px;
  font-size: 14px;
  margin-top: 28px;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    top: 0;
    left: -28px;
    background: var(--icon-bg-color);
    width: calc(100% + 56px);
  }
}

.nameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.name {
  font-size: 14px;
  font-weight: 500;
}

.by {
  color: var(--secondary-text);
}

.logo {
  width: 40px;
  height: 40px;
}
