@import "../../assets/styles/_breakpoints.scss";

.container {
  position: absolute;
  bottom: 0;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 40px;

  font-size: 14px;
  text-align: center;
  color: var(--white);

  @include desktop {
    grid-template-columns: 2fr 1fr 2fr;
    padding: 20px 40px;
  }

  .leftSection,
  .rightSection,
  .centerSection {
    display: flex;
    align-items: center;
  }

  .leftSection {
    text-align: left;
    display: none;

    span {
      font-size: 30px;
      font-weight: normal;
    }
    .bold {
      font-weight: 600;
    }
    @include desktop {
      display: block;
    }
  }

  .centerSection {
    font-size: 12px;
    justify-content: flex-start;
    gap: 9px;

    @include desktop {
      justify-content: center;
    }
  }

  .rightSection {
    justify-content: flex-end;
    gap: 32px;
  }

  .rightSectionContent {
    display: flex;
    align-items: center;
    gap: 20px;
    .transparent {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  > label {
    &:first-of-type {
      opacity: 0.5;
    }

    &:nth-of-type(2) {
      font-weight: 400;
    }
  }
}
