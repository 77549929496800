.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 35px;
  width: 100%;
  gap: 12px;
  padding: 28px 0 24px 0;
}

.rightContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
