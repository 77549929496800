@import "../../assets/styles/_breakpoints.scss";

.pageContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0 28px;
  overflow: auto;
  background: var(--gray-100);
  min-height: 100vh;
}

.fullScreen {
  padding: 0px;

  .pageHeader,
  .banner {
    padding: 0 50px;
    @include largeDesktop {
      padding: 0 80px;
    }
    @include extraLargeDesktop {
      padding: 0 120px;
    }
  }

  .pageContent {
    max-height: 100%;
  }
}

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  line-height: 1;
  h2 {
    font: normal normal 600 24px/1 Sora;
    letter-spacing: 0px;
    color: var(--primary-text);
    // TODO
    white-space: nowrap;
  }
}

.maxFullScreen {
  display: flex;
  max-height: 100%;
}

.pageContent {
  > div {
    width: 100%;
  }
}

.fullHeight {
  height: 100%;
  flex: 1;
}
