.customButton {
  &:global(.MuiButton-root) {
    transition: none;
    padding: 14px 20px 15px 20px;
    height: 44px;
    color: var(--white);

    &:global(.Mui-disabled) {
      background: var(--progress-bar-bg) !important;
      color: var(--border-color) !important;
    }

    &.primary {
      background: var(--green);
      color: var(--white);
      &:hover {
        background-color: var(--dark-green);
      }
    }

    &.neutral {
      background-color: var(--progress-bar-bg);
      color: var(--secondary-text);
      &:hover {
        background-color: var(--quaternary-button-focus);
      }
    }

    &.important {
      background-color: var(--red);
      color: var(--white);
      &:hover {
        background-color: var(--red-dark);
      }
    }

    &.transparent {
      background: transparent;
      border: 1px solid var(--white);
      color: var(--white);
    }

    &.info {
      background-color: white;
      border-radius: 50px;
      color: var(--green);
      display: flex !important;
      max-width: 150px;
    }

    &.textImportant {
      height: auto;
      padding: 0px !important;
      background: none;
      color: var(--red);
      &:hover {
        color: var(--red-dark);
      }
    }
  }
}

.buttonLoader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .spinner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid white;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: spin 0.6s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
