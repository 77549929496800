.toggle {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggleInput {
  opacity: 0;
  width: 0;
  height: 0;

  $active-color: var(--green);

  &.orange {
    $active-color: var(--orange);
  }

  .slider::before {
    background-color: var(--white);
  }

  &:checked + .slider {
    background-color: $active-color;
  }

  &:checked + .slider:before {
    transform: translateX(24px);
    background-color: var(--white);
  }

  &:disabled + .slider {
    opacity: 0.5;
    background-color: $active-color;
    cursor: not-allowed;
  }

  &:disabled + .slider::before {
    background-color: var(--white);
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-border);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 24px;

  &:hover {
    background-color: var(--gray-border-secondary);
  }

  &::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: var(--white);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
  }
}
