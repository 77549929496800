.textField {
  font-size: 14px;
  height: 100%;

  &:focus-visible {
    outline: none;
  }
  * {
    &:focus-visible {
      outline: none;
    }
  }

  :global(.MuiInputBase-root) {
    font-size: 14px;
    height: 100%;
    font-family: Sora, sans-serif;
    &:global(.Mui-focused) {
      :global(.MuiOutlinedInput-notchedOutline) {
        border-color: var(--border-color);
      }
    }
  }

  :global(.MuiOutlinedInput-root) {
    &:hover {
      :global(.MuiOutlinedInput-notchedOutline) {
        border-color: var(--border-color);
      }
    }
    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: var(--border-color);
      &:global(.Mui-focused) {
        border-color: var(--border-color);
        border-width: 2px;
      }
    }
    :global(.MuiOutlinedInput-input) {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255, 1);
      ::placeholder {
        font: normal normal normal 14px/25px Sora;
        color: #25273a;
        opacity: 0.3;
      }
    }

    :global(.MuiInputAdornment-root) {
      min-width: 30px;
      justify-content: center;
    }
  }
  &.sm {
    :global(.MuiOutlinedInput-input) {
      padding: 0 17px 0 15px;
    }
  }
  &.md {
    min-width: 200px;
  }

  &.fixedLg {
    width: 400px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
}
