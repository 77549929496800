@import "../../assets/styles/_breakpoints.scss";

.container {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 48px 40px;

  display: flex;
  align-items: center;

  font-size: 30px;
  font-weight: normal;
  color: var(--white);

  .bold {
    font-weight: 600;
  }

  @include desktop {
    display: none;
  }
}
